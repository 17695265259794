a:hover, a:focus {
  color: #000;
  text-decoration: none;
  outline: 0 none !important;
}

html {
  font-size: 13px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset {
  border: 0;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  display: block;
  border: 0 none;
}

address {
  font-style: normal;
  font-weight: normal;
}

caption {
  font-style: normal;
  font-weight: normal;
  text-align: left;
}

cite {
  font-style: normal;
  font-weight: normal;
}

code {
  font-style: normal;
  font-weight: normal;
}

dfn {
  font-style: normal;
  font-weight: normal;
}

em {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-style: normal;
  font-weight: normal;
}

th {
  font-style: normal;
  font-weight: normal;
  text-align: left;
}

var {
  font-style: normal;
  font-weight: normal;
}

ol {
  list-style: none;
}

ul {
  list-style: none;
}

h1 {
  font-size: 100%;
  font-weight: bold;
  font-family: Audrey-Bold;
  font-size: 96.9 .9rem;
  line-height: 9.8rem;
  font-weight: normal;
  color: #262f33;
  text-transform: uppercase;
  text-align: center;
}

h2 {
  font-size: 100%;
  font-weight: bold;
  font-size: 6.0rem;
  line-height: 6.1rem;
  font-weight: 300;
  color: #015388;
}

h3 {
  font-size: 100%;
  font-weight: bold;
  font-size: 5.3rem;
  line-height: 6.0rem;
  font-weight: 700;
  color: #fff;
}

h4 {
  font-size: 100%;
  font-weight: bold;
  font-size: 3.4rem;
  line-height: 3.6rem;
  font-weight: 700;
  color: #fff;
}

h5 {
  font-size: 100%;
  font-weight: bold;
  font-size: 25.5 .4rem;
  line-height: 2.7rem;
  color: #262f33;
  font-weight: 900;
}

h6 {
  font-size: 100%;
  font-weight: bold;
  font-size: 3.1rem;
  line-height: 3.6rem;
  font-weight: 300;
  color: #fff;
}

q:before {
  content: '';
}

q:after {
  content: '';
}

abbr {
  border: 0;
}

acronym {
  border: 0;
}

.clear {
  clear: both;
  font-size: 1px;
  line-height: 1px;
  display: block;
  height: 0;
}

.last {
  margin: 0 !important;
}

.pad_last {
  padding: 0 !important;
}

.no_bg {
  background: none !important;
}

.no_bor {
  border: 0 none !important;
}

header {
  display: block;
}

nav {
  display: block;
}

section {
  display: block;
}

article {
  display: block;
}

aside {
  display: block;
}

footer {
  display: block;
}

hgroup {
  display: block;
}

figure {
  display: block;
}

.btn {
  font-size: 22.5px;
  display: inline-block;
  padding: 0;
  background: #015388;
  height: 5.7rem;
  line-height: 5.7rem;
  letter-spacing: 1px;
  text-align: center;
  vertical-align: middle;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  outline: none;
  border: 1px solid #fff;
  color: #fff;
  font-family: PraxisComLight;
  font-weight: 300;
}

.btn:hover {
  background: #fff;
  border-color: #015388;
  color: #015388;
}

a {
  color: #000;
  text-decoration: none;
  outline: 0 none !important;
}

p {
  font-size: 2.0rem;
  line-height: 3.0rem;
  color: #015388;
  font-weight: 300;
}

@font-face {
  font-family: GothamMedium;
  src: url(../fonts/GothamMedium/GothamMedium.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamMedium/GothamMedium.woff) format("woff"), url(../fonts/GothamMedium/GothamMedium.ttf) format("truetype"), url(../fonts/GothamMedium/GothamMedium.svg#GothamMedium) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gotham-Book;
  src: url(../fonts/Gotham-Book/Gotham-Book.eot?#iefix) format("embedded-opentype"), url(../fonts/Gotham-Book/Gotham-Book.otf) format("opentype"), url(../fonts/Gotham-Book/Gotham-Book.woff) format("woff"), url(../fonts/Gotham-Book/Gotham-Book.ttf) format("truetype"), url(../fonts/Gotham-Book/Gotham-Book.svg#Gotham-Book) format("svg");
  font-weight: normal;
  font-style: normal;
}

.flt_lt {
  float: left;
  display: inline;
}

.flt_rt {
  float: right;
  display: inline;
}

.wrapper {
  margin: 0 auto;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.main-content {
  max-width: 1110px;
  margin: 0 auto;
}

.main-content .heritage-bottom {
  padding: 2rem;
}

.intro {
  background-image: url(../images/image1.jpg);
  background-size: cover;
  padding: 4rem 0;
  display: block;
}

.intro > div {
  margin: 0 auto;
  width: 80%;
  max-width: 1024px;
}

.heritage {
  padding: 0 0 2.0rem;
}

.heritage-bottom {
  background: #f6f6f8;
  padding: 5.0rem 0;
  width: 100%;
}

.heritage-bottom h2 {
  font-size: 1.6rem;
  line-height: 2.0rem;
  font-family: GothamMedium;
  color: #1d1371;
  padding: 0 0 1.5rem;
}

.heritage-bottom p {
  font-size: 1.2rem;
  line-height: 2.0rem;
  font-family: Gotham-Book;
  color: #1d1371;
  padding: 0 0 1.5rem;
}

.heritage-content {
  max-width: 78.8rem;
  margin: 0 auto;
}

.sun-sweet {
  margin: 4rem auto;
  width: 80%;
  max-width: 1024px;
}

@media (min-width: 640px) {
  .sun-sweet {
    display: flex;
  }
}

.sun-sweet .sun-sweet-left {
  display: inline-block;
  background: #eeeeee;
  padding: 2rem 1rem;
}

@media (min-width: 640px) {
  .sun-sweet .sun-sweet-left {
    width: 30%;
    max-width: 180px;
    float: left;
  }
}

.sun-sweet .sun-sweet-left figure {
  padding: 0 0 2.0rem;
}

.sun-sweet .sun-sweet-left figure img {
  margin: 0 auto;
}

.sun-sweet-right {
  background: #f6f6f8;
  width: 80%;
  float: right;
  display: inline-block;
  padding: 2.0rem 4.0rem;
}

@media (max-width: 640px) {
  .sun-sweet {
    padding: 0;
    margin: 2rem auto;
    width: 90%;
    background: #f6f6f8;
  }
  .sun-sweet-right {
    display: block;
    width: 100%;
    float: none;
  }
  .sun-sweet-left {
    display: block;
    width: 100%;
    float: none;
  }
  .sun-sweet-left figure:nth-child(1) {
    float: none;
    padding: 1rem;
  }
  .sun-sweet-left figure:nth-child(1) img {
    margin: 0rem auto;
    display: block;
  }
  .sun-sweet-left figure:nth-child(2) {
    display: none;
  }
}

.sun-sweet-content h2 {
  font-size: 1.6rem;
  line-height: 2.0rem;
  font-family: GothamMedium;
  color: #1d1371;
  padding: 0 0 1.5rem;
}

.sun-sweet-content h3 {
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-family: GothamMedium;
  color: #1d1371;
  padding: 0 0 1.5rem;
  font-weight: 800;
}

.sun-sweet-content p {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-family: Gotham-Book;
  color: #1d1371;
  padding: 0 0 1.5rem;
  font-weight: 400;
  max-width: 56.2rem;
}

@media (max-width: 640px) {
  .sun-sweet-content {
    text-align: center;
  }
}

.specifications {
  margin-top: 2rem;
  padding: 0 0 2.0rem;
}

.specifications header {
  padding: 2rem 1rem;
}

.specifications h2 {
  font-size: 1.6rem;
  line-height: 2.0rem;
  font-family: GothamMedium;
  color: #1d1371;
  padding: 0 0 1.5rem;
  text-align: center;
}

.specifications p {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-family: Gotham-Book;
  color: #1d1371;
  padding: 0rem;
  text-align: center;
}

.specifications table {
  max-width: 1024px;
  margin: 0 auto;
  border: 1px solid #333;
}

.specifications table tbody tr.top td {
  background-color: #1d2469;
}

.specifications table tbody tr.top td.brand.header {
  background-color: #1d2469;
}

.specifications table tbody tr.top td h2 {
  color: #fff;
}

.specifications table td {
  border: 1px solid #333;
  width: 25%;
  padding: 10px;
}

.specifications table .brand.header {
  background: #757575;
}

.specifications table .brand.header h2 {
  padding: .5rem;
  margin: 0;
  color: #ffffff;
  font-weight: normal;
  font-family: Gotham-Book;
}

.specifications table tr.brand.header {
  display: none;
}

.specifications table tr.brand.header td p {
  margin: 0;
  padding: 0;
}

@media (max-width: 640px) {
  .specifications table td.brand.header {
    display: none;
  }
  .specifications table tr.brand.header {
    display: table-row;
  }
}

.specifications-table {
  margin: 0 auto;
  border: 1px solid #000;
  border-bottom: 0;
}

.specifications-table div.fg {
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-content: center;
}

.specifications-table div.fg h2 {
  margin: 0;
  padding: 0;
}

.specifications-table div.fg > div {
  flex: 1;
  vertical-align: middle;
  height: 100px;
}

@media (max-width: 640px) {
  .specifications-table {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-right: 0;
    border-left: 0;
  }
  .specifications-table .brand {
    width: 33%;
    flex: 0 33%;
  }
  .specifications-table .brand.header {
    display: block;
    width: 100%;
    background: #1d1371;
  }
  .specifications-table .brand.header h2 {
    color: #ffffff;
    padding: .5rem;
  }
  .specifications-table div.fg {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .specifications-table div.fg div {
    flex: 0 33%;
  }
}

.specifications-head {
  padding: 0;
  border-bottom: 1px solid #000;
}

.brand h2 {
  font-size: 1.4rem;
  line-height: 2.0rem;
  font-family: GothamMedium;
  color: #1d1371;
  padding: 2.0rem 0;
  text-align: center;
  font-weight: 800;
}

.brand p {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-family: GothamMedium;
  color: #1d1371;
  padding: 2.0rem .5rem;
  text-align: center;
  font-weight: 400;
}

.brand1 {
  background: #f8f6f9;
}

.about {
  max-width: 30.0rem;
  margin: 0 auto;
  padding: 0 0 2.0rem;
}

.about-left {
  float: left;
  display: inline-block;
  width: 30%;
  padding: 0 1.0rem 0 0;
}

.about-left figure img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.about-right {
  float: right;
  display: inline-block;
  width: 70%;
  padding: 0 0 0 1.0rem;
  border-left: 1px solid #000;
}

.about-right a {
  font-size: 1.3rem;
  line-height: 1.4em;
  color: #1d2469;
  display: block;
  text-decoration: none;
  font-family: GothamMedium;
}

.about-right a.call {
  display: inline-block;
}

.about-right p {
  font-size: 1.2rem;
  line-height: 1.4em;
  color: #1d2469;
  display: block;
  text-decoration: none;
  font-family: GothamMedium;
  padding: 8px 0 0;
}

.prunes {
  position: relative;
}

.prunes > figure > img {
  width: 100%;
  height: 61.1rem;
}

.logo img {
  margin: 0 auto;
}

div.top {
  padding: 6.0rem 0;
  background: #f7ef80;
  position: relative;
  background-image: url(/images/pageglow.jpg);
  background-size: 100% 100%;
}

div.top .logo {
  margin-bottom: 2rem;
}

.bulk {
  text-align: center;
  padding: 4rem;
}

.bulk h2 {
  font-size: 4.0rem;
  line-height: 1.2em;
  color: #1d2469;
  font-family: GothamMedium;
  padding: 0 0 3.0rem;
}

.bulk p {
  font-size: 2.4rem;
  line-height: 1.2em;
  color: #1d2469;
  font-family: GothamMedium;
  z-index: 22;
  position: relative;
}

@media (max-width: 640px) {
  .bulk {
    padding: 2rem;
  }
  .bulk h2 {
    font-size: 2.8rem;
  }
  .bulk p {
    font-size: 1.5rem;
  }
}

.bulk .fruits {
  position: absolute;
  top: 80%;
  right: 0rem;
}

.bulk .fruits img {
  width: 180px;
}

@media (max-width: 640px) {
  .bulk .fruits {
    display: none;
  }
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.heritage-top figure img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.car > figure {
  padding: 0 0 2.0rem;
}

.car > figure > img {
  width: 100%;
  height: auto;
}

.sun-sweet-left .logo {
  width: 320px;
}
