//colors
$color_venice_blue_approx: #015388;
$white: #fff;
$black: #000;
$color_charade_approx: #262f33;
$color_black_haze_approx: #f6f6f8;
$color_deep_koamaru_approx: #1d1371;
$color_whisper_approx: #f8f6f9;
$color_lucky_point_approx: #1d2469;
$sunflower: #f2a93c;
$sspurple: #1d2469;

//fonts
$font_0: GothamMedium;
$font_1: Gotham-Book;
$font_2: PraxisComLight;
$font_3: Audrey-Bold;
$font_4: GothamMedium;

//urls
$url_0: url(../fonts/GothamMedium/GothamMedium.eot?#iefix);
$url_1: url(../fonts/GothamMedium/GothamMedium.woff);
$url_2: url(../fonts/GothamMedium/GothamMedium.ttf);
$url_3: url(../fonts/GothamMedium/GothamMedium.svg#GothamMedium);
$url_4: url(../fonts/Gotham-Book/Gotham-Book.eot?#iefix);
$url_5: url(../fonts/Gotham-Book/Gotham-Book.otf);
$url_6: url(../fonts/Gotham-Book/Gotham-Book.woff);
$url_7: url(../fonts/Gotham-Book/Gotham-Book.ttf);
$url_8: url(../fonts/Gotham-Book/Gotham-Book.svg#Gotham-Book);

//@extend-elements
//original selectors
//a:hover, a:focus
%extend_1 {
  color: $black;
  text-decoration: none;
  outline: 0 none !important;
}

html {
  font-size: 13px;
}

* {
  margin: 0;
  padding: 0;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset {
  border: 0;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  display: block;
  border: 0 none;
}

address {
  font-style: normal;
  font-weight: normal;
}

caption {
  font-style: normal;
  font-weight: normal;
  text-align: left;
}

cite {
  font-style: normal;
  font-weight: normal;
}

code {
  font-style: normal;
  font-weight: normal;
}

dfn {
  font-style: normal;
  font-weight: normal;
}

em {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-style: normal;
  font-weight: normal;
}

th {
  font-style: normal;
  font-weight: normal;
  text-align: left;
}

var {
  font-style: normal;
  font-weight: normal;
}

ol {
  list-style: none;
}

ul {
  list-style: none;
}

h1 {
  font-size: 100%;
  font-weight: bold;
  font-family: $font_3;
  font-size: 96.9 .9rem;
  line-height: 9.8rem;
  font-weight: normal;
  color: $color_charade_approx;
  text-transform: uppercase;
  text-align: center;
}

h2 {
  font-size: 100%;
  font-weight: bold;
  font-size: 6.0rem;
  line-height: 6.1rem;
  font-weight: 300;
  color: $color_venice_blue_approx;
}

h3 {
  font-size: 100%;
  font-weight: bold;
  font-size: 5.3rem;
  line-height: 6.0rem;
  font-weight: 700;
  color: $white;
}

h4 {
  font-size: 100%;
  font-weight: bold;
  font-size: 3.4rem;
  line-height: 3.6rem;
  font-weight: 700;
  color: $white;
}

h5 {
  font-size: 100%;
  font-weight: bold;
  font-size: 25.5 .4rem;
  line-height: 2.7rem;
  color: $color_charade_approx;
  font-weight: 900;
}

h6 {
  font-size: 100%;
  font-weight: bold;
  font-size: 3.1rem;
  line-height: 3.6rem;
  font-weight: 300;
  color: $white;
}

q {
  &:before {
    content: '';
  }

  &:after {
    content: '';
  }
}

abbr {
  border: 0;
}

acronym {
  border: 0;
}

.clear {
  clear: both;
  font-size: 1px;
  line-height: 1px;
  display: block;
  height: 0;
}

.last {
  margin: 0 !important;
}

.pad_last {
  padding: 0 !important;
}

.no_bg {
  background: none !important;
}

.no_bor {
  border: 0 none !important;
}

header {
  display: block;
}

nav {
  display: block;
}

section {
  display: block;
}

article {
  display: block;
}

aside {
  display: block;
}

footer {
  display: block;
}

hgroup {
  display: block;
}

figure {
  display: block;
}

.btn {
  font-size: 22.5px;
  display: inline-block;
  padding: 0;
  background: $color_venice_blue_approx;
  //width: 31.2rem;
  height: 5.7rem;
  line-height: 5.7rem;
  letter-spacing: 1px;
  text-align: center;
  vertical-align: middle;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  outline: none;
  border: 1px solid $white;
  color: $white;
  font-family: $font_2;
  font-weight: 300;

  &:hover {
    background: $white;
    border-color: $color_venice_blue_approx;
    color: $color_venice_blue_approx;
  }
}

a {
  color: $black;
  text-decoration: none;
  outline: 0 none !important;

  &:hover {
    @extend %extend_1;
  }

  &:focus {
    @extend %extend_1;
  }
}

p {
  font-size: 2.0rem;
  line-height: 3.0rem;
  color: $color_venice_blue_approx;
  font-weight: 300;
}

@font-face {
  font-family: $font_0;
  src: $url_0 format('embedded-opentype'), $url_1 format('woff'), $url_2 format('truetype'), $url_3 format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font_1;
  src: $url_4 format('embedded-opentype'), $url_5 format('opentype'), $url_6 format('woff'), $url_7 format('truetype'), $url_8 format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
}

body {
}

.flt_lt {
  float: left;
  display: inline;
}

.flt_rt {
  float: right;
  display: inline;
}

.wrapper {
  //max-width: 117.0rem;
  margin: 0 auto;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.main-content {
  max-width: 1110px;
  margin: 0 auto;

  .heritage-bottom {
    padding: 2rem;
  }
}

.intro {
  background-image: url(../images/image1.jpg);
  background-size: cover;
  padding: 4rem 0;
  display: block;

  > div {
    margin: 0 auto;
    width: 80%;
    max-width: 1024px;
  }
}

.heritage {
  padding: 0 0 2.0rem;
}

.heritage-bottom {
  background: $color_black_haze_approx;
  padding: 5.0rem 0;
  width: 100%;

  h2 {
    font-size: 1.6rem;
    line-height: 2.0rem;
    font-family: $font_0;
    color: $color_deep_koamaru_approx;
    padding: 0 0 1.5rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 2.0rem;
    font-family: $font_1;
    color: $color_deep_koamaru_approx;
    padding: 0 0 1.5rem;
  }
}

.heritage-content {
  max-width: 78.8rem;
  margin: 0 auto;
}

.innovation {
}

.sun-sweet {
  //margin: 2rem;

  margin: 4rem auto;
  width: 80%;
  max-width: 1024px;
  @media (min-width: 640px) {
    display: flex;

  }

  .sun-sweet-left {
    display: inline-block;

    background: #eeeeee;
    padding: 2rem 1rem;
    @media (min-width: 640px) {
      width: 30%;
      max-width: 180px;
      float: left;
    }

    figure {
      padding: 0 0 2.0rem;

      img {
        margin: 0 auto;
      }
    }


  }
}


.sun-sweet-right {
  background: $color_black_haze_approx;
  width: 80%;
  float: right;
  display: inline-block;
  padding: 2.0rem 4.0rem;
}

@media(max-width: 640px) {
  .sun-sweet {
    padding: 0;
    margin: 2rem auto;
    width: 90%;
    background: #f6f6f8;

  }
  .sun-sweet-right {
    display: block;
    width: 100%;
    float: none;
  }
  .sun-sweet-left {

    display: block;
    width: 100%;
    float: none;

    figure:nth-child(1) {
      float: none;
      padding: 1rem;

      //&:after {
      //  content: " ";
      //  display: block;
      //  background: $sunflower;
      //  height: 1px;
      //  width: 64px;
      //  margin: 3rem auto 2rem;
      //}

      img {
        margin: 0rem auto;
        display: block;
      }
    }

    figure:nth-child(2) {
      display: none;
    }
  }
}

.sun-sweet-content {
  h2 {
    font-size: 1.6rem;
    line-height: 2.0rem;
    font-family: $font_0;
    color: $color_deep_koamaru_approx;
    padding: 0 0 1.5rem;
  }

  h3 {
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-family: $font_0;
    color: $color_deep_koamaru_approx;
    padding: 0 0 1.5rem;
    font-weight: 800;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-family: $font_1;
    color: $color_deep_koamaru_approx;
    padding: 0 0 1.5rem;
    font-weight: 400;
    max-width: 56.2rem;
  }

  @media(max-width: 640px) {
    text-align: center;
  }
}

.specifications {
  margin-top: 2rem;
  padding: 0 0 2.0rem;

  header {
    padding: 2rem 1rem;
  }

  h2 {
    font-size: 1.6rem;
    line-height: 2.0rem;
    font-family: $font_0;
    color: $color_deep_koamaru_approx;
    padding: 0 0 1.5rem;
    text-align: center;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-family: $font_1;
    color: $color_deep_koamaru_approx;
    //padding: 0 0 4.0rem;
    padding: 0rem;
    text-align: center;
  }
}

.specifications {
  table {
    max-width: 1024px;
    margin: 0 auto;
    border: 1px solid #333;

    tbody {

      tr.top {
        td {
          background-color: $sspurple;
          &.brand.header {
            background-color: $sspurple;
          }
          h2 {
            color: #fff;

          }
        }
      }
    }

    td {
      border: 1px solid #333;
      width: 25%;
      padding: 10px;
    }

    .brand.header {
      background: #757575;

      h2 {
        padding: .5rem;
        margin: 0;
        color: #ffffff;
        font-weight: normal;
        font-family:Gotham-Book;
      }
    }
    tr.brand.header {
      display: none;

      td {

        p {
          margin: 0;
          padding: 0;
        }
      }


    }

    @media(max-width: 640px) {
      td.brand.header {
        display: none;
      }

      tr.brand.header {
        display: table-row;
      }
    }
  }

}

.specifications-table {
  //width: 117.0rem;
  margin: 0 auto;
  border: 1px solid $black;
  border-bottom: 0;

  div.fg {
    width: 100%;
    display: flex;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    h2 {
      margin: 0;
      padding: 0;
    }

    //align-items: center;
    justify-content: center;

    > div {
      flex: 1;

      vertical-align: middle;
      height: 100px;

    }

  }

  @media(max-width: 640px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-right: 0;
    border-left: 0;
    .brand {
      width: 33%;
      flex: 0 33%;
    }
    .brand.header {
      display: block;
      width: 100%;
      background: $color_deep_koamaru_approx;

      h2 {
        color: #ffffff;
        padding: .5rem;
      }
    }
    div.fg {
      width: 100%;
      display: flex;
      justify-content: space-between;

      div {
        flex: 0 33%
      }
    }
  }
}

.specifications-content {
}

.specifications-head {
  padding: 0;
  border-bottom: 1px solid $black;
}

.brand {
  //display: inline-block;
  //width: 25%;
  //float: left;

  h2 {
    font-size: 1.4rem;
    line-height: 2.0rem;
    font-family: $font_0;
    color: $color_deep_koamaru_approx;
    padding: 2.0rem 0;
    text-align: center;
    font-weight: 800;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-family: $font_0;
    color: $color_deep_koamaru_approx;
    padding: 2.0rem .5rem;
    text-align: center;
    font-weight: 400;
  }
}

.brand1 {
  background: $color_whisper_approx;
}

.about {
  max-width: 30.0rem;
  margin: 0 auto;
  padding: 0 0 2.0rem;
}

.about-left {
  float: left;
  display: inline-block;
  width: 30%;
  padding: 0 1.0rem 0 0;

  figure img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.about-right {
  float: right;
  display: inline-block;
  width: 70%;
  padding: 0 0 0 1.0rem;
  border-left: 1px solid $black;

  a {
    font-size: 1.3rem;
    line-height: 1.4em;
    color: $color_lucky_point_approx;
    display: block;
    text-decoration: none;
    font-family: $font_0;

    &.call {
      display: inline-block;
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 1.4em;
    color: $color_lucky_point_approx;
    display: block;
    text-decoration: none;
    font-family: $font_0;
    padding: 8px 0 0;
  }
}

.prunes {
  position: relative;

  figure {
  }

  > figure > img {
    width: 100%;
    height: 61.1rem;
  }
}

.logo {
  //position: absolute;
  //top: 5.1rem;
  //left: 16.3rem;
  img {
    margin: 0 auto;

  }
}

div.top {
  padding: 6.0rem 0;

  background: rgb(247, 239, 128);
  //background: -moz-linear-gradient(left, rgba(247, 239, 128, 1) 0%, rgba(242, 238, 179, 1) 10%, rgba(249, 249, 249, 1) 25%, rgba(255, 255, 255, 1) 75%, rgba(242, 238, 179, 1) 90%, rgba(247, 239, 128, 1) 100%);
  //background: -webkit-linear-gradient(left, rgba(247, 239, 128, 1) 0%, rgba(242, 238, 179, 1) 10%, rgba(249, 249, 249, 1) 25%, rgba(255, 255, 255, 1) 75%, rgba(242, 238, 179, 1) 90%, rgba(247, 239, 128, 1) 100%);
  //background: linear-gradient(to right, rgba(247, 239, 128, 1) 0%, rgba(242, 238, 179, 1) 10%, rgba(249, 249, 249, 1) 25%, rgba(255, 255, 255, 1) 75%, rgba(242, 238, 179, 1) 90%, rgba(247, 239, 128, 1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7ef80', endColorstr='#f7ef80', GradientType=1);
  position: relative;
  background-image: url(/images/pageglow.jpg);
  background-size: 100% 100%;
  .logo {
    margin-bottom: 2rem;
  }
}

.bulk {
  //position: absolute;
  //top: 21.6rem;
  //left: 16.3rem;
  text-align: center;
  padding: 4rem;

  h2 {
    font-size: 4.0rem;
    line-height: 1.2em;
    color: $color_lucky_point_approx;
    font-family: $font_4;
    padding: 0 0 3.0rem;
  }

  p {
    font-size: 2.4rem;
    line-height: 1.2em;
    color: $color_lucky_point_approx;
    font-family: $font_0;
    z-index: 22;
    position: relative;
  }

  @media(max-width: 640px) {
    padding: 2rem;
    h2 {
      font-size: 2.8rem;
    }
    p {
      font-size: 1.5rem;
    }
  }

  .fruits {
    position: absolute;
    top: 80%;
    right: 0rem;

    img {
      width: 180px;
    }

    @media(max-width: 640px) {
      display: none;
    }

  }

}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.heritage-top figure img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.car > figure {
  padding: 0 0 2.0rem;

  > img {
    width: 100%;
    height: auto;
  }
}

.sun-sweet-left {
  .logo {
    width: 320px;
    //max-width: 80%;
  }
}
